<template>
	<div>
		<vx-card class="mb-base">
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<vs-input class="w-full" label="Domain" v-model="domainSearch['name']" @keyup.enter="search()" />
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<vs-input class="w-full" label="Domain ID" v-model="domainSearch['id']"  @keyup.enter="search()" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButton" id="button-with-loading"  class="mb-2 mr-4" @click="search()">Search</vs-button>
					<vs-button class="mb-2" color="danger" type="border" @click="resetDetails()">Reset</vs-button>
				</div>
			</div>
		</vx-card>

		<!-- No Results Alert -->
		<div v-if="searchFlags['searched'] && searchFlags['noResults']" class="vx-col w-full mb-base">
			<vs-alert active="true" icon="new_releases">No domains found for the above search criteria, please change the search criteria & try again.</vs-alert>
		</div>

		<!-- Domain Lifecycle Details -->
		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<vx-card class="mb-base" v-for="(item, index) in domainDetails['lifecycle'].slice().reverse()" :key="index">
				<div slot="no-body">
					<div class="vx-card__header">
						<div class="vx-card__title">
							<h4>{{ `${domainLifecycleNumbers(index)}Domain details - ${domainDetails['name']}` }}</h4>
						</div>
						<div class="inline-block">
							<vs-button v-if="showActionButton(index, item['locked'], 'locked') == '0'" class="mr-4" color="danger" type="border" size="small" @click="registryLock(item['domain_id'], 'lock')">Lock</vs-button>
							<vs-button v-if="showActionButton(index, item['locked'], 'locked') == '1'" class="mr-4" color="danger" type="border" size="small" @click="registryLock(item['domain_id'], 'unlock')">Unlock</vs-button>
							<vs-button v-if="showActionButton(index, item['status'], 'status') == 'takedown'" color="primary" type="border" size="small" @click="takedown(item['domain_id'])">Takedown</vs-button>
							<vs-button v-if="showActionButton(index, item['status'], 'status') == 'unsuspend'" color="primary" type="border" size="small" @click="unsuspend(item['domain_id'])">Unsuspend</vs-button>
						</div>
					</div>
					<div class="vx-row p-6">
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain ID" :value="item['domain_id']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Creation Date" :value="item['transaction_date']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Period" :value="formatYears(item['period'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrar Name" :value="item['registrar_organization']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Name" :value="item['registrant_name']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Email" :value="item['registrant_email']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Country" :value="item['registrant_country']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Phone" :value="item['registrant_phone_voice']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Status" :value="formatStatus(item['status'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<label class="vs-input--label">Nameservers</label>
									<vs-textarea class="w-full label-input" :value="item['nameservers']" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>

			</vx-card>
		</template>

		<!-- Abuse Log -->
		<vx-card v-show="searchFlags['searched'] && !searchFlags['noResults']" title="Abuse Log">
			<div slot="no-body" class="p-6 pt-0">
				<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--small"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="tableData"
					rowSelection="multiple"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:suppressRowClickSelection="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:sideBar="sideBar"
					:rowHeight="38"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				domainSearch: {
					name: '',
					id: '',
				},
				domainDetails: {
					name: '',
					tld: '',
					lifecycle: [],
				},
				searchFlags: {
					searched: false,
					noResults: false,
				},
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agTextColumnFilter",
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Source ID',
					field: 'source_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Category ID',
					field: 'category_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Category',
					field: 'category',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Transaction Date',
					field: 'transaction_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Detection Date',
					field: 'detection_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Action Date',
					field: 'action_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Registrar',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
					hide: true,
				},
				{
					headerName: 'Status',
					field: 'status',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'A':
									return `<div class="badge badge--success inline">${data.value}</div>`
								case 'SH':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'SHP':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'SHF':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'PD':
									return `<div class="badge badge--info inline">${data.value}</div>`
								case 'D':
									return `<div class="badge badge--dark inline">${data.value}</div>`
								default:
									return `<div>-</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 100,
				},
				{
					headerName: 'Talos',
					field: 'suspicion_level',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'high':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'medium':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'low':
									return `<div class="badge badge--primary inline">${data.value}</div>`
								default:
									return `<div>${data.value}</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Whitelisted',
					field: 'whitelisted',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Locked',
					field: 'locked',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Reserved',
					field: 'reserved',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Reviewed',
					field: 'reviewed',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Manual',
					field: 'manual',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Nameservers',
					field: 'nameservers',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant ID',
					field: 'registrant_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant Email',
					field: 'registrant_email',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?registrant_email=${encodeURIComponent(data.value)}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant Phone',
					field: 'registrant_phone_voice',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?registrant_phone=${encodeURIComponent(data.value)}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant State',
					field: 'registrant_state',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Registrant Country',
					field: 'registrant_country',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source All ID',
					field: 'source_all_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Row ID',
					field: 'id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					hide: true,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				tableData: [],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			paramId() {
				return this.$route.params.id ? this.$route.params.id : null;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading > .con-vs-loading");
			},
			domainLifecycleNumbers(index) {
				let length = this.domainDetails['lifecycle'].length;

				return length > 1 ? `${length - index}. `: '';
			},
			formatYears(years) {
				return years == 1 ? `${years} year` : `${years} years`;
			},
			formatStatus(status) {
				switch (status) {
					case 'SH':
						return 'Server Hold';
					case 'D':
						return 'Deleted';
					case 'PD':
						return 'Pending Delete';
					case 'SHF':
						return 'Server Hold Failed';
					case 'SHP':
						return 'Server Hold In Progress';
					default:
						return 'Active';
				}
			},
			showActionButton(index, state, type) {
				if(index == 0) {
					if(type == 'status') {
						if (state == 'A') {
							return 'takedown';
						}
						else if (state == 'SH') {
							return 'unsuspend';
						}
						else {
							return false;
						}
					}
					else if (type == 'locked') {
						if (state == '0') {
							return '0';
						}
						else if (state == '1') {
							return '1';
						}
						else {
							return false;
						}
					}
					else {
						return false;
					}
				}
				else {
					return false;
				}
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			resetDetails(flag) {
				if(flag != 'searched') {
					this.domainSearch = {
						name: '',
						id: '',
					};
					this.searchFlags = {
						searched: false,
						noResults: false,
					};
					this.$router.replace('/domain-search');
				}
				this.domainDetails = {
					name: '',
					tld: '',
					lifecycle: [],
				};
				this.tableData = [];
			},
			populateDomainId(id) {
				if(id) {
					this.domainSearch['id'] = id;
					// Search for domain
					this.search();
				}
			},
			search() {
				let self = this,
					name = this.domainSearch['name'],
					id = this.domainSearch['id'];

				if(id != '') {
					// Show loader
					this.showButtonLoading();
					// Search by Domain ID
					this.$axiosSecure.get('/domain-search-id', {
						params: {
							id: id
						}
					})
					.then((response) => {
						// Set Searched Flag
						this.searchFlags['searched'] = true;
						// Assign Data
						if(!response.data.noResults) {
							this.domainDetails = response.data.details;
							this.tableData = response.data.abuse;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
						}
						else {
							this.resetDetails('searched');
							this.searchFlags['noResults'] = true;
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Confimation notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				}
				else if(name != '') {
					// Show loader
					this.showButtonLoading();
					// Search by Domain Name
					this.$axiosSecure.get('/domain-search-name', {
						params: {
							name: name
						}
					})
					.then((response) => {
						// Set Searched Flag
						this.searchFlags['searched'] = true;
						// Assign Data
						if(!response.data.noResults) {
							this.domainDetails = response.data.details;
							this.tableData = response.data.abuse;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
						}
						else {
							this.resetDetails('searched');
							this.searchFlags['noResults'] = true;
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				}
			},
			takedown(id) {
				// Save takedown domains
				this.$store.dispatch("setTakedownDomains", [{
					domain: this.domainDetails['name'],
					domain_id: id,
					tld: this.domainDetails['tld']
				}]);
				// Redirect to listing
				this.$router.push({ path: '/suspension' })
			},
			unsuspend(id) {
				// Save takedown domains
				this.$store.dispatch("setUnsuspensionDomains", [{
					domain: this.domainDetails['name'],
					domain_id: id,
					tld: this.domainDetails['tld']
				}]);
				// Redirect to listing
				this.$router.push({ path: '/unsuspension' })
			},
			registryLock(id, type) {
				// Save lock domains
				this.$store.dispatch("setRegistryLockDomains", [{
					domain: this.domainDetails['name'],
					domain_id: id,
					tld: this.domainDetails['tld']
				}]);
				if (type == 'lock') {
					// Redirect to listing
					this.$router.push({ path: '/registry-lock/lock' })	
				}
				else if (type == 'unlock') {
					// Redirect to listing
					this.$router.push({ path: '/registry-lock/unlock' })
				}
			},
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Prepopulate Domain Id
			this.populateDomainId(this.paramId);
		}
	}
</script>
